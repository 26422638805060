import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import api from '../../api'
import {
  CreationFlowType,
  EventType,
  ExperienceType,
  ICelebration,
  ICreateCelebrationPayload,
  ICreateScheduledNotificationForRecipientOfCelebrationPayload,
  IDgcPhoto,
  IDigitalGreetingCard,
  IGiftCard,
  InteractiveOpen,
  IPatchCelebrationPayload,
  IPatchCelebrationResponse,
  SharingMethod,
} from '../../api/types'
import { RootState } from '../store'
import { omit, orderBy } from 'lodash'

export type ICelebrationState = Omit<
  ICelebration,
  'experienceType' | 'recipientContactId' | 'eventType' | 'interactiveOpen'
> & {
  interactiveOpen: InteractiveOpen
  eventType: EventType
  sendLinkToRecipientAt: string | null
  recipientContactId: number | null
  digitalGreetingCardId: number | null
  experienceType: ExperienceType
  giftCard: IGiftCard | null
  creationFlowType: CreationFlowType
  scheduledSendEmail: boolean
  scheduledSendPush: boolean
  scheduleTimezone: string | null
}

export interface CelebrationState {
  recipientName: string
  createCelebrationError: string
  createScheduledEmailError: string
  createScheduledNotificationError: string
  isCreatingCelebration: boolean
  celebration: ICelebrationState
  isUploadingVideoMessageToS3: boolean
  videoMessageToS3UploadError: string
  isCreatingScheduledEmail: boolean
  isCreatingScheduledNotification: boolean
  isFetchingCelebration: boolean
  fetchCelebrationError: string
  isPatchingCelebration: boolean
  patchCelebrationError: string
  preSelectedGiftCard: string | null
}

const getInitialState = (): CelebrationState => ({
  recipientName: '',
  preSelectedGiftCard: null,
  celebration: {
    id: '',
    eventType: EventType.BIRTHDAY,
    recipientContactId: null,
    experienceType: ExperienceType.DIGITAL_GREETING_CARD,
    interactiveOpen: InteractiveOpen.BIRTHDAY_DEFAULT,
    giftCardAmount: 0,
    giftCard: null,
    giftCardCode: null,
    digitalGreetingCardPrice: 0,
    digitalGreetingCardMessage: '',
    digitalGreetingCardId: null,
    digitalGreetingCard: null,
    backgroundMusicPrice: 0,
    senderName: '',
    subjectName: '',
    sendLinkToRecipientAt: null,
    checkoutCompleted: false,
    dgcPhotos: [],
    videoOverlayUrl: '',
    giftCardMessage: '',
    creationFlowType: CreationFlowType.EXPERIENCE_FIRST,
    thankYouSentAt: null,
    scheduledSendEmail: false,
    scheduledSendPush: false,
    scheduleTimezone: null,
    sharingMethod: null,
    parentCelebrationId: null,
  },
  createCelebrationError: '',
  isCreatingCelebration: false,
  isUploadingVideoMessageToS3: false,
  videoMessageToS3UploadError: '',
  isCreatingScheduledEmail: false,
  isCreatingScheduledNotification: false,
  createScheduledEmailError: '',
  createScheduledNotificationError: '',
  isFetchingCelebration: false,
  fetchCelebrationError: '',
  isPatchingCelebration: false,
  patchCelebrationError: '',
})

const initialState: CelebrationState = getInitialState()

export const createCelebration = createAsyncThunk(
  'celebration/createCelebration',
  async function (payload: ICreateCelebrationPayload, { rejectWithValue }) {
    try {
      const endpointPayload = {
        ...omit(payload, ['digitalGreetingCard', 'giftCard']),
      } as ICreateCelebrationPayload
      const celebration = await api.createCelebration(endpointPayload)

      if (!celebration) {
        throw new Error()
      }
      return celebration
    } catch (err) {
      return rejectWithValue(
        (err as Error).message || 'Error creating celebration'
      )
    }
  }
)

export const createScheduledNotificationForRecipientOfCelebrationPayload =
  createAsyncThunk(
    'celebration/createScheduledNotificationForRecipientOfCelebrationPayload',
    async function (
      {
        celebrationId,
        payload,
      }: {
        celebrationId: string
        payload: ICreateScheduledNotificationForRecipientOfCelebrationPayload
      },
      { rejectWithValue }
    ) {
      try {
        const response =
          await api.createScheduledNotificationForRecipientOfCelebrationPayload(
            {
              celebrationId,
              payload,
            }
          )
        return response
      } catch (err) {
        return rejectWithValue(
          (err as Error).message ||
            'Error creating scheduled notification for recipient'
        )
      }
    }
  )

export const patchCelebration = createAsyncThunk(
  'celebration/patchCelebration',
  async function ({
    celebrationId,
    payload,
  }: {
    celebrationId: string
    payload: IPatchCelebrationPayload
  }) {
    const endpointPayload = {
      ...omit(payload, ['digitalGreetingCard', 'giftCard']),
    } as IPatchCelebrationResponse
    if (payload.digitalGreetingCard) {
      endpointPayload.digitalGreetingCardId = payload.digitalGreetingCard.id
    }

    const response = await api.patchCelebration({
      celebrationId,
      payload: endpointPayload,
    })
    return response
  }
)

export const getCelebration = createAsyncThunk(
  'celebration/getCelebration',
  async function (celebrationId: string, { rejectWithValue }) {
    try {
      const response = await api.getCelebrationWithId(celebrationId)
      return response
    } catch (err) {
      return rejectWithValue((err as Error).message || '')
    }
  }
)

export const setSharingMethodForCelebration = createAsyncThunk(
  'celebration/setSharingMethodForCelebration',
  async function (
    {
      celebrationId,
      sharingMethod,
    }: {
      celebrationId: string
      sharingMethod: SharingMethod
    },
    { rejectWithValue }
  ) {
    try {
      const response = await api.setSharingMethodForCelebration(
        celebrationId,
        sharingMethod
      )
      return response
    } catch (err) {
      return rejectWithValue((err as Error).message || '')
    }
  }
)

export const celebrationSlice = createSlice({
  name: 'celebration',
  initialState,
  reducers: {
    setSenderName: (state: CelebrationState, action: PayloadAction<string>) => {
      state.celebration.senderName = action.payload
    },
    setRecipientName: (
      state: CelebrationState,
      action: PayloadAction<string>
    ) => {
      state.recipientName = action.payload
    },
    setRecipientContactId: (
      state: CelebrationState,
      { payload: { id } }: PayloadAction<{ id: number }>
    ) => {
      state.celebration.recipientContactId = id
    },
    initiateCelebrationCreationWithBirthdayEventType: (
      state: CelebrationState
    ) => {
      state.celebration.eventType = EventType.BIRTHDAY
    },
    initiateCelebrationCreation: () => {
      const initialState = getInitialState()
      return initialState
    },
    resetWithCelebrationParams: (
      _state: CelebrationState,
      action: PayloadAction<{
        state: Partial<ICelebrationState>
        preSelectedGiftCard: string | null
      }>
    ) => {
      const initialState = getInitialState()
      return {
        ...initialState,
        preSelectedGiftCard: action.payload.preSelectedGiftCard,
        celebration: {
          ...initialState.celebration,
          ...action.payload.state,
        },
      }
    },
    setVideoOverlay: (
      state: CelebrationState,
      {
        payload: { url, interactiveOpen },
      }: PayloadAction<{ url: string; interactiveOpen: InteractiveOpen }>
    ) => {
      state.celebration.videoOverlayUrl = url
      state.celebration.interactiveOpen =
        interactiveOpen || InteractiveOpen.BIRTHDAY_DEFAULT
    },
    setDgcPhotos: (
      state: CelebrationState,
      { payload }: PayloadAction<IDgcPhoto[]>
    ) => {
      state.celebration.dgcPhotos = payload
    },
    setGiftCard: (
      state: CelebrationState,
      {
        payload,
      }: PayloadAction<{
        giftCard: IGiftCard
        giftCardCode: string
        amount: number
      }>
    ) => {
      state.celebration.giftCard = payload.giftCard
      state.celebration.giftCardCode = payload.giftCardCode
      state.celebration.giftCardAmount = payload.amount
    },

    setDigitalGreetingCard: (
      state: CelebrationState,
      {
        payload: { digitalGreetingCard, digitalGreetingCardMessage },
      }: PayloadAction<{
        digitalGreetingCard: IDigitalGreetingCard
        digitalGreetingCardMessage: string
      }>
    ) => {
      state.celebration.digitalGreetingCardId = digitalGreetingCard.id
      state.celebration.digitalGreetingCardPrice = digitalGreetingCard.price
      state.celebration.digitalGreetingCard = digitalGreetingCard
      state.celebration.digitalGreetingCardMessage = digitalGreetingCardMessage
      state.celebration.interactiveOpen = digitalGreetingCard.interactiveOpen
    },

    setCelebrationId: (state, { payload }: PayloadAction<string>) => {
      state.celebration.id = payload
    },
    resetCelebrationState: () => getInitialState(),
  },
  extraReducers: (builder) => {
    builder.addCase(
      createScheduledNotificationForRecipientOfCelebrationPayload.rejected,
      (state, action) => {
        state.createScheduledNotificationError = action.error.message || ''
        state.isCreatingScheduledNotification = false
      }
    )

    builder.addCase(
      createScheduledNotificationForRecipientOfCelebrationPayload.fulfilled,
      (state, { payload }) => {
        state.celebration.sendLinkToRecipientAt =
          payload?.sendLinkToRecipientAt || null
        state.celebration.scheduledSendEmail = !!payload?.scheduledSendEmail
        state.celebration.scheduledSendPush = !!payload?.scheduledSendPush
        state.celebration.scheduleTimezone = payload?.scheduleTimezone || null
        state.isCreatingScheduledNotification = false
      }
    )

    builder.addCase(createCelebration.pending, (state) => {
      state.isCreatingCelebration = true
    })

    builder.addCase(createCelebration.fulfilled, (state, { payload }) => {
      state.celebration = {
        ...state.celebration,
        ...payload,
      }
      state.isCreatingCelebration = false
    })

    builder.addCase(createCelebration.rejected, (state, action) => {
      state.isCreatingCelebration = false
      state.createCelebrationError = action.error.message || ''
    })

    builder.addCase(getCelebration.pending, (state) => {
      state.isFetchingCelebration = true
      state.fetchCelebrationError = ''
    })

    builder.addCase(getCelebration.fulfilled, (state, { payload }) => {
      state.celebration = {
        ...state.celebration,
        ...payload,
      }
      state.isFetchingCelebration = false
    })

    builder.addCase(getCelebration.rejected, (state, action) => {
      state.isFetchingCelebration = false
      state.fetchCelebrationError = action.error.message || ''
    })

    builder.addCase(patchCelebration.pending, (state, action) => {
      const update = {
        ...state.celebration,
        ...action.meta.arg.payload,
      }
      state.celebration = update

      state.isPatchingCelebration = true
      state.patchCelebrationError = ''
    })

    builder.addCase(patchCelebration.fulfilled, (state, { payload }) => {
      state.celebration = {
        ...state.celebration,
        ...payload,
      }
      state.isPatchingCelebration = false
    })

    builder.addCase(patchCelebration.rejected, (state, action) => {
      state.isPatchingCelebration = false
      state.patchCelebrationError = action.error.message || ''
    })

    builder.addCase(
      setSharingMethodForCelebration.fulfilled,
      (state, { payload }) => {
        state.celebration = {
          ...state.celebration,
          ...payload,
        }
      }
    )
  },
})

export const {
  setRecipientName,
  setRecipientContactId,
  setGiftCard,
  setDigitalGreetingCard,
  setSenderName,
  setVideoOverlay,
  resetCelebrationState,
  resetWithCelebrationParams,
  initiateCelebrationCreationWithBirthdayEventType,
  initiateCelebrationCreation,
  setDgcPhotos,
  setCelebrationId,
} = celebrationSlice.actions

export const selectCelebrationState = (state: RootState) => {
  return state.celebration
}

export const selectIsBabyMilestoneEvent = createSelector(
  selectCelebrationState,
  ({ celebration: { eventType } }) => {
    return [
      EventType.BABY_MILESTONE_1,
      EventType.BABY_MILESTONE_3,
      EventType.BABY_MILESTONE_6,
      EventType.BABY_MILESTONE_9,
    ].includes(eventType)
  }
)

export const selectIsThankYouEvent = createSelector(
  selectCelebrationState,
  ({ celebration: { eventType } }) => {
    return eventType === EventType.THANK_YOU
  }
)

export const selectIsGrandparentsDayEvent = createSelector(
  selectCelebrationState,
  ({ celebration: { eventType } }) => {
    return eventType === EventType.GRANDPARENTS_DAY
  }
)

export const selectIsThanksgivingEvent = createSelector(
  selectCelebrationState,
  ({ celebration: { eventType } }) => {
    return eventType === EventType.THANKSGIVING
  }
)

export const selectIsNonXmasHolidayEvent = createSelector(
  selectCelebrationState,
  ({ celebration: { eventType, interactiveOpen } }) => {
    return (
      eventType === EventType.HOLIDAY &&
      interactiveOpen === InteractiveOpen.HOLIDAY_DEFAULT
    )
  }
)

export const selectIsXmasEvent = createSelector(
  selectCelebrationState,
  ({ celebration: { interactiveOpen } }) => {
    return interactiveOpen === InteractiveOpen.HOLIDAY_XMAS
  }
)

export const selectIsNewYearEvent = createSelector(
  selectCelebrationState,
  ({ celebration: { eventType } }) => {
    return eventType === EventType.NYE
  }
)

export const selectCelebrationViewUrl = ({
  celebration: { celebration },
}: RootState) => {
  let url = ''
  const viewRoute = `celebrate/view/${celebration.id}/`
  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1' ||
    window.location.hostname === ''
  ) {
    url = `${window.location.protocol}://${window.location.hostname}:${window.location.port}/${viewRoute}`
  } else {
    url = `${window.location.protocol}//${window.location.hostname}/${viewRoute}`
  }
  return url
}

export const selectDgcPhotoState = createSelector(
  selectCelebrationState,
  (state) => {
    const photosState = state.celebration.dgcPhotos.reduce(
      (agg, photo) => {
        const fileName = photo.url.split('/').pop() as string

        return {
          ...agg,
          [fileName]: {
            ...photo,
            fileName,
            base64Data: '',
            isUploading: false,
            error: '',
            isUploaded: true,
          },
        }
      },
      {} as Record<
        string,
        IDgcPhoto & {
          fileName: string
          base64Data: string
          isUploading: boolean
          error: string
          isUploaded: boolean
        }
      >
    )

    return {
      photosState,
      photosOrder: orderBy(photosState, 'order', 'asc').map(
        (photo) => photo.fileName
      ),
    }
  }
)

export default celebrationSlice.reducer
