import { useSearchParams } from 'react-router-dom'

const AuthError = () => {
  const [searchParams] = useSearchParams()

  const error = searchParams.get('error')
  return (
    <div className="h-screen items-center justify-center flex bg-fa-light-gray">
      <div className="bg-white border-fa-attention border text-fa-attention p-3 rounded-sm">
        The action could not be completed.
        <br />
        <div>
          Details: <span className="font-mono">{error}</span>
        </div>
        <div>Please try again via the FamilyAlbum App.</div>
      </div>
    </div>
  )
}

export default AuthError
