import React, { useEffect } from 'react'
import { Provider as RollbarProvider } from '@rollbar/react'
import intl from 'react-intl-universal'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'
import { store } from './store'
import { selectContactsError } from './store/slices/contactsSlice'
import { useAppSelector } from './store/hooks'
import { selectAuthError } from './store/slices/authSlice'
import Root from './Root'
import { ConfigContext, config } from './ConfigContext'
import './index.css'

const AppToaster = () => {
  const contactsErrors = useAppSelector(selectContactsError)
  const authError = useAppSelector(selectAuthError)
  const appErrors = contactsErrors || authError

  useEffect(() => {
    if (appErrors) {
      toast.error(intl.get(`api.errors.${appErrors}`).defaultMessage(appErrors))
    }
  }, [appErrors])

  return <Toaster toastOptions={{ duration: 10000 }} />
}

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <ConfigContext.Provider value={config}>
      <RollbarProvider config={config.rollbar}>
        <Provider store={store}>
          <BrowserRouter>
            <Root
              familyAlbumStoreDeeplink={config.familyAlbumStoreDeeplink}
              currentOccasions={config.currentOccasions}
              familyAlbumDownloadDeeplink={config.familyAlbumDownloadDeeplink}
              familyAlbumAutologinDeeplink={config.familyAlbumAutologinDeeplink}
              holidayExplanationVideoUrl={config.holidayExplanationVideoUrl}
              explanationVideoUrl={config.explanationVideoUrl}
              nyeExplanationVideoUrl={config.nyeExplanationVideoUrl}
              clarityProjectId={config.clarityProjectId}
              testRoutesEnabled={config.testRoutesEnabled}
              modalRedirectUrl={config.modalRedirectUrl}
              mothersDayExplanationVideoUrl={
                config.mothersDayExplanationVideoUrl
              }
              fathersDayExplanationVideoUrl={
                config.fathersDayExplanationVideoUrl
              }
            />
          </BrowserRouter>
          <AppToaster />
        </Provider>
      </RollbarProvider>
    </ConfigContext.Provider>
  </React.StrictMode>
)
