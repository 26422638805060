import Spinner from '../components/Spinner'

const Loading = ({ text }: { text?: string }) => (
  <div className="flex justify-center items-center h-screen flex-col">
    {text && <div>{text}</div>}
    <div className="overflow-hidden flex items-center justify-center w-full">
      <Spinner size={6} />
    </div>
  </div>
)

export default Loading
