export type ContactId = number
export type BirthdayDay = number | null
export type BirthdayMonth = number | null
export type BirthdayYear = number | null

export const enum DgcPhotoMode {
  PORTRAIT = 'PORTRAIT',
  LANDSCAPE = 'LANDSCAPE',
}

export const enum EventType {
  BIRTHDAY = 'BIRTHDAY',
  HOLIDAY = 'HOLIDAY',
  NYE = 'NYE',
  THANK_YOU = 'THANK_YOU',
  MOTHERS_DAY = 'MOTHERS_DAY',
  FATHERS_DAY = 'FATHERS_DAY',
  BABY_MILESTONE_1 = 'BABY_MILESTONE_1',
  BABY_MILESTONE_3 = 'BABY_MILESTONE_3',
  BABY_MILESTONE_6 = 'BABY_MILESTONE_6',
  BABY_MILESTONE_9 = 'BABY_MILESTONE_9',
  GRANDPARENTS_DAY = 'GRANDPARENTS_DAY',
  NEW_BABY = 'NEW_BABY',
  THANKSGIVING = 'THANKSGIVING',
}

export const enum ExperienceType {
  DIGITAL_GREETING_CARD = 'DIGITAL_GREETING_CARD',
  GIFT_CARD_MESSAGE = 'GIFT_CARD_MESSAGE',
}

export const enum CreationFlowType {
  EXPERIENCE_FIRST = 'EXPERIENCE_FIRST',
  GIFT_CARD_FIRST = 'GIFT_CARD_FIRST',
}

export const enum InteractiveOpen {
  BIRTHDAY_DEFAULT = 'BIRTHDAY_DEFAULT',
  HOLIDAY_DEFAULT = 'HOLIDAY_DEFAULT',
  HOLIDAY_XMAS = 'HOLIDAY_XMAS',
  NYE = 'NYE',
  MOTHERS_DAY = 'MOTHERS_DAY',
  FATHERS_DAY = 'FATHERS_DAY',
}

export enum PhotoSources {
  FAMILYALBUM = 'FAMILYALBUM',
  DEVICE = 'DEVICE',
}

export enum SharingMethod {
  SCHEDULE = 'SCHEDULE',
  MANUAL = 'MANUAL',
}

export interface ICelebrationForRecipientResponse
  extends Omit<
    ICelebration,
    'digitalGreetingCardPrice' | 'backgroundMusicPrice'
  > {
  recipientName: string
  senderName: string
  giftCard: IGiftCardView
  recipientOpenedLinkAt: string | null
}

export interface IGiftCard {
  code: string
  cardImageUrl: string
  currencyCode: string
  name: string
  denominationType: 'fixed' | 'open'
  minimumValue: number
  maximumValue: number
  availableDenominations: number[]
  description: string
  descriptionMarkdownUrl: string
  termsAndConditionsHtml: string
  termsAndConditionsMarkdownUrl: string
  priority: number
  order: number
  customOccasions: string[]
  isPopular?: boolean
  provider: 'RUNA' | 'FAMILY_ALBUM'
}

export type IGiftCardView = Omit<
  IGiftCard,
  | 'priority'
  | 'availableDenominations'
  | 'denominationType'
  | 'maximumValue'
  | 'minimumValue'
>

export interface IDigitalGreetingCard {
  id: number
  price: number
  bgColor: string
  textColor: string
  fontSizePx: number
  coverImageFileName: string
  bgMusicFileName: string
  font: string
  interactiveOpen: InteractiveOpen
  photos: { url: string }[]
}

export type PhotoSource = PhotoSources.FAMILYALBUM | PhotoSources.DEVICE

export interface IDgcPhoto {
  url: string
  order: number
  width: number
  height: number
  mode: DgcPhotoMode
  source: PhotoSource
}

export interface ISavedDgcPhoto extends IDgcPhoto {
  id: number
  deleted: boolean
}

export interface ICreateCelebrationPayload {
  eventType: EventType
  experienceType?: ExperienceType
  interactiveOpen?: InteractiveOpen
  creationFlowType?: CreationFlowType
  giftCardCode?: string | null
  giftCardAmount?: number
  giftCard?: IGiftCard | null
}

export enum Relationship {
  child = 'child',
  son = 'son',
  daughter = 'daughter',
  aunt = 'aunt',
  uncle = 'uncle',
  mother = 'mother',
  father = 'father',
  grandmother = 'grandmother',
  grandfather = 'grandfather',
  sister = 'sister',
  brother = 'brother',
  friend = 'friend',
  husband = 'husband',
  wife = 'wife',
  partner = 'partner',
  cousin = 'cousin',
  newphew = 'newphew',
  niece = 'niece',
}

export interface ICelebration {
  id: string
  interactiveOpen: InteractiveOpen
  eventType: EventType
  experienceType: ExperienceType
  creationFlowType: CreationFlowType
  backgroundMusicPrice: number
  digitalGreetingCardPrice: number
  digitalGreetingCardMessage: string
  digitalGreetingCardId: number | null
  digitalGreetingCard: IDigitalGreetingCard | null
  giftCardCode: string | null
  giftCardAmount: number
  recipientContactId: number
  senderName: string
  subjectName: string
  checkoutCompleted: boolean
  dgcPhotos: IDgcPhoto[]
  videoOverlayUrl: string
  giftCardMessage: string | null
  thankYouSentAt: string | null
  sharingMethod: SharingMethod | null
  parentCelebrationId: string | null
}

export interface IPatchCelebrationPayload {
  eventType?: EventType
  experienceType?: ExperienceType
  digitalGreetingCard?: IDigitalGreetingCard | null
  digitalGreetingCardMessage?: string
  dgcPhotos?: IDgcPhoto[]
  giftCardCode?: string | null
  giftCardAmount?: number
  giftCard?: IGiftCard | null
  videoOverlayUrl?: string
  interactiveOpen?: InteractiveOpen
  recipientContactId?: number
  senderName?: string
  giftCardMessage?: string
  sharingMethod?: SharingMethod
  creationFlowType?: CreationFlowType
}

export interface IPatchCelebrationResponse {
  eventType?: EventType
  experienceType?: ExperienceType
  digitalGreetingCardId?: number | null
  digitalGreetingCardMessage?: string
  dgcPhotos?: IDgcPhoto[]
  giftCardCode?: string | null
  giftCardAmount?: number
  interactiveOpen?: InteractiveOpen
  recipientContactId?: number
  senderName?: string
  giftCardMessage?: string
  creationFlowType: CreationFlowType
}

export enum OnboardingStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

export interface Contact {
  id: number
  name: string
  familyId?: string
  birthdayDay: BirthdayDay
  birthdayMonth: BirthdayMonth
  birthdayYear: BirthdayYear
  familyAlbumUserId?: string
  familyAlbumChildId?: string
  faRelationshipsToChildren: {
    faChildId: string
    relationshipName: Relationship | string
    childName: string
  }[]
  isFamilyAlbumContact: boolean
  isChild: boolean
  isFamilyAdmin: boolean
  timezone?: string | null
  currencyCode: string | null
  relationshipType: Relationship | null
  relationshipName: string | null
  lastActiveOnFamilyAlbum: string | null
  wasBirthdayAddedBySelf: boolean
  email?: string | null
}

export interface ContactExtended extends Contact {
  nameCount: number
  familyName?: string
}

export interface OnboardingContactExtended extends ContactExtended {
  isFirst?: boolean
  isLast?: boolean
  current?: number
  total?: number
  isLastFamily?: boolean
}

export type ContactPayload = {
  name: string
  birthdayDay: BirthdayDay
  birthdayMonth: BirthdayMonth
  birthdayYear: BirthdayYear
  relationshipName?: string | null
}

export type Contacts = Contact[]

export interface LoginPayload {
  email: string
  password: string
}

export interface SignupPayload {
  email: string
  password: string
  uuid?: string
}

export interface ResetPasswordPayload {
  email: string
  password: string
  passwordResetToken: string
}

export interface UpdateProfileRequestPayload {
  birthdayYear?: number | null
  birthdayMonth: number | null
  birthdayDay: number | null
}

export interface RegisterEmailRequestPayload {
  email: string
}

export interface CreateCheckoutSessionPayload {
  celebrationId: string
  giftCardProviderId?: number | null
  giftCardAmount?: number | null
}

export type FamiliesMetadata = {
  createdAt: string
  familyId: string
  isAdmin: boolean
}[]

export interface ICreateScheduledNotificationForRecipientOfCelebrationPayload {
  day: number
  month: number
  sendEmail: boolean
  recipientEmail?: string
  sendPush: boolean
  time: number
  timezone: string
}

export type FamiliesMetadataWithContacts = {
  familyCreatedAt?: string
  isAdmin?: boolean
  contacts: Contact[]
  name: string
}[]

export interface Profile {
  id: string
  email: string
  name: string
  onboardingStatus: OnboardingStatus
  onboardingVersion: string
  ownerOfFaId?: number | null
  userAdminFamilies: FamiliesMetadata
  birthdayDay: BirthdayDay | null
  birthdayMonth: BirthdayMonth | null
  birthdayYear: BirthdayYear | null
  emailConfirmed: boolean
  userTimezone: string | null
  currencyCode: string | null
  settings: {
    timezoneCountry: string
    giftCardPricingDisplayVersion: 'A' | 'B'
  }
}

export interface ICelebrationForSenderResponse extends ICelebration {
  giftCard: IGiftCard
  stripePayments: unknown[]
  sendLinkToRecipientAt: string | null
  scheduledSendEmail: boolean
  scheduledSendPush: boolean
  scheduleTimezone: string | null
}

export type ProfileResponse = Profile

export interface GetTransitionCodeResponse {
  transitionCode: string
}

export interface IFamilyAlbumPhotoSummaryResponseFamily {
  id: number
  name: string
  relationName: string
  thumbnails: {
    all: string | null
    recommended: string | null
    favorite: string | null
  }
  counts: {
    all: number
    recommended: number
    favorite: number
  }
}

export type IFamilyAlbumCuratedPhotosResponse = {
  width: number
  height: number
  uuid: string
  tookAt: string
  url: string
}[]

export type IFamilyAlbumPhotoSummaryResponse =
  IFamilyAlbumPhotoSummaryResponseFamily[]

export type IMediaFile = {
  uuid: string
  url: string
  width: number
  height: number
  tookAt: string
}

export interface IFamilyAlbumMediaFilesResponse {
  hasNext: boolean
  cursor: string
  mediaFiles: IMediaFile[]
}

export enum ICelebrateFamilyAlbumMediaFilter {
  ALL = 'all',
  FAVORITE = 'favorite',
  RECOMMENDED = 'recommended',
}

export interface RedeemGiftCardResponse {
  provider: 'RUNA' | 'FAMILY_ALBUM'
  deliveryUrl: string | null
  redemptionCode: string | null
}
