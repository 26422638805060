import cx from 'classnames'
import Lottie from 'lottie-react'
import animationData from '../animations/spinner.json'

const Spinner = ({ className, size }: { className?: string; size: number }) => {
  return (
    <Lottie
      animationData={animationData}
      className={cx(className, {
        'w-32 h-32': size === 6,
        'w-20 h-20': size === 4,
      })}
      autoplay
    />
  )
}

export default Spinner
